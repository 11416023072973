.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* .blocklyToolboxDiv {
  @apply bg-vs-light-toolbar;
} */

/* .blocklyMainBackground {
  fill: #1e1e1e !important;
} */


/* Makes our label white. */
.blocklyTreeLabel {
  color: white;
}
/* Adds padding around the group of categories and separators. */
.blocklyToolboxContents {
  padding: 0.5em;
}
/* Adds space between the categories, rounds the corners and adds space around the label. */
.blocklyTreeRow {
  margin: 0.2em 0;
  border-radius: 4px;
  height: 30px;
  padding: 3px;
}

.deneyap-theme .blocklyToolboxDiv::-webkit-scrollbar {
    width: 0; /* Scrollbar genişliği */
}

.deneyap-theme .blocklyTreeLabel {
  color: #565B61 !important;
  font-weight: 600;
}

.deneyap-theme .blocklyTreeSelected > div > .blocklyTreeLabel {
  color: #fff !important;
}

.deneyap-theme .blocklyToolboxContents {
  padding: 0.2em;
}

.deneyap-theme .blocklyTreeRow {
  margin: 0.2em 0;
  border-radius: 0px;
  height: 30px;
  padding: 3px;
}

.deneyap-theme .blocklyToolboxDiv {
  padding: 0px;
}

/* .deneyap-theme .blocklyScrollbarVertical {
  width: 8px;
} */

.deneyap-theme .blocklyScrollbarHorizontal .blocklyScrollbarHandle {
  height: 6px;
}

.deneyap-theme .blocklyScrollbarVertical .blocklyScrollbarHandle {
  width: 6px;
}

.deneyap-theme .blocklyFlyoutScrollbar .blocklyScrollbarHandle {
  fill: #008080 !important;
}