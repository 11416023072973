@tailwind base;
@tailwind components;
@tailwind utilities;

.fit {
  @apply w-full h-full;
}

.monaco-editor {
  @apply border-none;
}

#godot_canvas div.loading-area {
  padding: 10% 0;
  text-align: center;
}

#godot_canvas div.progress-bar {
  background-color: #1a1a1a;
  width: 40%;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
  text-align: center;
}

#godot_canvas div.stripes {
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
#godot_canvas div.stripes.animated {
  animation: animate-stripes 0.6s linear infinite;
}

#godot_canvas div.stripes.animated.slower {
  animation-duration: 1s;
}

#godot_canvas div.stripes.reverse {
  animation-direction: reverse;
}

#godot_canvas div.progress-bar-inner {
  display: block;
  height: 30px;
  width: 0%;
  background-color: #05e075;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  position: relative;
  text-align: center;
}

#godot_canvas div .progres-bar-text {
  padding-top: 50px;
  margin-top: 50px;
  color: aliceblue;
  text-align: center;
}

#godot_canvas gif#gif {
  margin: 0;
  padding: 0;
  background-color: red;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -ms-fill-available;
}

#output p.output {
  color: white;
}

#output p.error {
  color: red;
}

#output > p {
  padding: 0;
  margin: 2px;
  white-space: nowrap;
}

#output > p::before {
  content: ">> ";
}
#output > p.blinking {
  visibility: collapse;
  animation: 1.5s blink linear infinite;
}

@keyframes blink {
  0% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
}

.brighten_animation {
  color: #060;
  transition: 3s color, 500ms font-size;
  font-size: 28px;
}
.brighten_animation:hover {
  color: #0f0;
  transition: 500ms color, 500ms font-size;
  font-size: 32px;
}

canvas:focus-visible {
  outline: none;
}

.scrollbar::-webkit-scrollbar {
  @apply w-1.5;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.scrollbar::-webkit-scrollbar-thumb {
  @apply bg-riders-green outline-riders-green outline-1 rounded;
}

.messagebox .ant-message-notice-content {
  @apply bg-vs-dark-border text-white mt-10;
}

.messagebox .ant-message-notice-content .anticon-spin {
  @apply text-teal-blue;
}